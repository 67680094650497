import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { CircularProgress } from "@material-ui/core";
import { Person as PersonIcon } from "@material-ui/icons";

import getLogger, { LogGroup } from "js/core/logger";

import Api from "js/core/api";
import { getStaticUrl } from "legacy-js/config";
import Thumbnails from "js/core/models/thumbnails";
import { FirebaseUserConsumer } from "legacy-js/react/views/Auth/FirebaseUserContext";
import { Authenticate } from "legacy-js/react/views/Auth/Authenticate";
import NotificationsService from "js/core/services/notifications";
import ErrorDialog from "legacy-js/react/components/Dialogs/ErrorDialog";

import { AuthPage, AuthForm } from "legacy-js/react/views/Auth/AuthUi";
import { LoadingPage } from "legacy-js/react/components/LoadingPage";
import TextArea from "legacy-js/react/components/TextArea";
import { BlueButton, BlueOutlinedButton } from "legacy-js/react/components/UiComponents";

const logger = getLogger(LogGroup.PERMISSIONS);

export const RequestAccessDialogContextType = {
    PLAYER: "player",
    EDITOR: "editor"
};

const Divider = styled.hr`
    border: 1px solid #eeeeee;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: #F8FAFC;
`;

const ContentContainer = styled.div.attrs(({ opacity }) => ({
    style: {
        opacity
    }
}))`
    width: 100%;
    height: 100%;
    transition: opacity 500ms;
`;

const ErrorDialogMessage = styled.p`
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;     
    letter-spacing: 0.5px;
`;

const ErrorDialogInfo = styled.p`
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;
`;

const AuthFormHeader = styled.p`
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;
    letter-spacing: 0.5px;
    text-align: center;
    color: #333333;
`;

const AuthFormSubheader = styled.p`
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;
    text-align: center;
    color: #222222;
`;

const AuthFormSubheaderHeavy = styled.p`
    font-family: Source Sans Pro;    
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;    
    letter-spacing: 0.5px;
    text-align: center;
    color: #222222;
`;

const RequestAccessButton = styled(BlueButton)`
&&& {
    width: 100%;
    height: 40px;
    border-radius: 1px;
}
`;

const UserInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
`;

const UserInfo = styled.div`
    width: fit-content;
    background: #eeeeee;

    padding: 10px;
    display: flex;
    flex-flow: row;
    align-items: center;

    >img,>svg {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    >p {
        font-family: Source Sans Pro;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #222222;
    }
`;

const LogIntoDifferentAccountButton = styled.div.attrs(({ disabled }) => ({
    style: {
        pointerEvents: disabled ? "none" : "auto",
        color: disabled ? "#CCCCCC" : "#11A9E2",
        cursor: disabled ? "default" : "pointer"
    }
}))`
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    text-decoration-line: underline;
`;

const DoneImageContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DoneImage = styled.img`
    height: 100%;
`;

const ReturnToLibraryButtonContainer = styled.div`
    margin-top: 34px;
    margin-bottom: -16px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ReturnToLibraryButton = styled(BlueOutlinedButton)`
    &&& {
        height: 40px;
        border: none;
    }
`;

const LinkButton = styled.span`
    color: #11A9E2;
    cursor: pointer;
    text-decoration: underline;
`;

class RequestAccessDialogContent extends React.Component {
    constructor() {
        super();

        this.state = {
            fetchingContext: true,
            fetchingRequestAccess: false,
            accessRequested: false,
            error: null,
            thumbnailUrl: null,
            customMessage: "",
            context: null
        };
    }

    componentDidMount() {
        this.load();
    }

    setStateAsync(stateUpdates) {
        return new Promise(resolve => this.setState(stateUpdates, resolve));
    }

    async load() {
        const { contextType, presentationOrLinkId, goToUrl } = this.props;

        try {
            const context = await Api.requestAccessContext.get({ id: presentationOrLinkId, contextType });

            if (contextType === RequestAccessDialogContextType.PLAYER) {
                if (context.hasCollaborationReadPermission || context.presentationIsPublic) {
                    // Redirect to player, already has view access
                    goToUrl(context.playerUrl);
                    return;
                }
            } else if (contextType === RequestAccessDialogContextType.EDITOR) {
                if (context.hasCollaborationReadPermission) {
                    // Redirect to player, has collaboration view access
                    goToUrl(context.playerUrl);
                    return;
                }
            }

            let thumbnailUrl = null;
            if (context.canRequestThumbnail) {
                const { firstSlideId, firstSlideModifiedAt } = context;
                thumbnailUrl = await Thumbnails.getSignedUrlAndLoad(firstSlideId, firstSlideModifiedAt, presentationOrLinkId);
            }

            await this.setStateAsync({ fetchingContext: false, thumbnailUrl, context });
        } catch (err) {
            logger.error(err, "RequestAccessDialogContent load() failed", { contextType, presentationOrLinkId });

            this.setState({
                fetchingContext: false,
                error: err.status === 404
                    ? {
                        message: "Sorry! We weren’t able to find this presentation.",
                        info: "Either the link is wrong, or this presentation has been deleted."
                    }
                    : {
                        message: "Oops! Our server had an error.",
                        info: "Try again later."
                    }
            });
        }
    }

    handleRequestAccess = () => {
        const { contextType, presentationOrLinkId, goToUrl } = this.props;
        const { customMessage } = this.state;

        this.setState({ fetchingRequestAccess: true });

        NotificationsService.notifyOnPermissionRequested(
            presentationOrLinkId,
            contextType === RequestAccessDialogContextType.EDITOR ? "write" : "read",
            customMessage
        )
            .then(() => {
                this.setState({ fetchingRequestAccess: false, accessRequested: true });
                goToUrl("/", 10000);
            })
            .catch(err => {
                logger.error(err, "RequestAccessDialogContent handleRequestAccess() failed", { contextType, presentationOrLinkId });

                this.setState({
                    fetchingRequestAccess: false,
                    error: {
                        message: "Oops! Our server had an error.",
                        info: "Try again later."
                    }
                });
            });
    }

    render() {
        const {
            contextType,
            firebaseUser,
            goToUrl
        } = this.props;
        const {
            fetchingContext,
            error,
            context,
            thumbnailUrl,
            customMessage,
            fetchingRequestAccess,
            accessRequested
        } = this.state;

        if (fetchingContext) {
            return <LoadingPage />;
        }

        if (error) {
            return (<ErrorDialog
                message={(<>
                    <ErrorDialogMessage>{error.message}</ErrorDialogMessage>
                    <ErrorDialogInfo>{error.info}</ErrorDialogInfo>
                </>)}
                preventClose
                hideBackdrop
                closeDialog={() => goToUrl("/")}
            />);
        }

        const authPageHero = thumbnailUrl
            ? { imageUrl: thumbnailUrl, caption: context.presentationName, isSlide: true }
            : { image: "generic" };

        if (accessRequested) {
            return (
                <AuthPage hero={authPageHero}>
                    <AuthForm>
                        <DoneImageContainer>
                            <DoneImage src={getStaticUrl("/images/auth/done-check.gif")} />
                        </DoneImageContainer>
                        <AuthFormHeader>Your request has been sent!</AuthFormHeader>
                        <AuthFormSubheader>We’ll notify you when you request has been approved.</AuthFormSubheader>
                        <ReturnToLibraryButtonContainer>
                            <ReturnToLibraryButton onClick={() => goToUrl("/")}>return to library</ReturnToLibraryButton>
                        </ReturnToLibraryButtonContainer>
                    </AuthForm>
                </AuthPage >
            );
        }

        let title;
        if (contextType === RequestAccessDialogContextType.EDITOR) {
            title = "You don't have permission to edit this presentation.";
        } else if (contextType === RequestAccessDialogContextType.PLAYER) {
            title = "You don't have access to this presentation.";
        }

        return (
            <AuthPage hero={authPageHero}>
                <AuthForm title={title}>
                    <AuthFormSubheader>Request access, or log in to an account with access.</AuthFormSubheader>
                    <TextArea
                        isVisible
                        readOnly={fetchingRequestAccess}
                        text={customMessage}
                        onChange={customMessage => this.setState({ customMessage })}
                        placeholder="Add a message (optional)..."
                    />
                    <RequestAccessButton
                        onClick={this.handleRequestAccess}
                        disabled={fetchingRequestAccess}
                    >
                        {fetchingRequestAccess && <CircularProgress size={24} />}
                        {!fetchingRequestAccess && "request access"}
                    </RequestAccessButton>
                    <Divider />
                    {context.presentationIsPublic &&
                        <AuthFormSubheader>
                            This presentation is public. If you’d like to view it instead, click <LinkButton onClick={() => goToUrl(context.playerUrl)}>here</LinkButton>.
                        </AuthFormSubheader>
                    }
                    {context.presentationIsPublic && <Divider />}
                    <AuthFormSubheaderHeavy>You are currently logged into Beautiful.ai as:</AuthFormSubheaderHeavy>
                    <UserInfoContainer>
                        <UserInfo>
                            {firebaseUser.photoURL && <img src={firebaseUser.photoURL} />}
                            {!firebaseUser.photoURL && <PersonIcon />}
                            <p>{firebaseUser.displayName ?? firebaseUser.email}</p>
                        </UserInfo>
                    </UserInfoContainer>
                    <LogIntoDifferentAccountButton
                        disabled={fetchingRequestAccess}
                        onClick={() => goToUrl(`/logout?continue=${encodeURIComponent(window.location.pathname)}`)}
                    >
                        Log into a different account
                    </LogIntoDifferentAccountButton>
                </AuthForm>
            </AuthPage >
        );
    }
}

class RequestAccessDialog extends React.Component {
    static propTypes = {
        contextType: PropTypes.string.isRequired,
        presentationOrLinkId: PropTypes.string.isRequired
    }

    constructor() {
        super();

        this.state = {
            isFadingOut: false
        };

        this.gotToUrlTimeout = null;
    }

    fadeOut = () => new Promise(resolve => {
        this.setState({ isFadingOut: true });
        setTimeout(resolve, 500);
    });

    goToUrl = (url, delayMs = 0) => {
        clearTimeout(this.gotToUrlTimeout);
        this.gotToUrlTimeout = setTimeout(
            () => this.fadeOut().then(() => window.location = url),
            delayMs
        );
    }

    render() {
        const { contextType, presentationOrLinkId } = this.props;
        const { isFadingOut } = this.state;

        return (
            <Container>
                <Authenticate defaultPage="signIn" reason="presentation-invite">
                    <FirebaseUserConsumer>
                        {firebaseUser => (
                            <ContentContainer opacity={isFadingOut ? 0 : 1}>
                                <RequestAccessDialogContent
                                    firebaseUser={firebaseUser}
                                    contextType={contextType}
                                    presentationOrLinkId={presentationOrLinkId}
                                    goToUrl={this.goToUrl}
                                />
                            </ContentContainer>
                        )}
                    </FirebaseUserConsumer>
                </Authenticate>
            </Container>
        );
    }
}

export default RequestAccessDialog;
