import React from "react";

import AppContext from "./Context/AppContext";
import OfflineContext from "./Context/OfflineContext";
import PlayerView from "./Components/PlayerView";

export enum PlayerContextType {
    APP = "app",
    OFFLINE = "offline"
}

interface PlayerProps {
    contextType: PlayerContextType;
    onExit?: () => void;
    startSlideIndex?: number;
    openPresenterOnPlaybackStarted?: boolean;
    enterFullscreenOnPlaybackStarted?: boolean;
    presentationOrLinkId?: string;
    isPreviewFromEditor?: boolean;
}

const Player = React.forwardRef(function player(props: PlayerProps, ref) {
    const {
        contextType = PlayerContextType.APP,
        ...playerProps
    } = props;

    const Context = (contextType === PlayerContextType.APP ? AppContext : OfflineContext) as any;
    return (
        <Context {...playerProps}>
            {contextData => (
                <PlayerView
                    ref={ref}
                    {...props}
                    // Note: context may override props values (e.g. presentation) so it goes after
                    {...contextData}
                />
            )}
        </Context>
    );
});

export default Player;
