import React from "reactn";
import { app } from "js/namespaces";
import linkDS, { LinkType } from "legacy-js/react/views/PresentationSettings/dataservices/LinkDataService";
import { ShowConfirmationDialog, ShowMessageDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { PresentationPrivacyType } from "js/core/models/presentation";

export class IntegratorController {
    static initApp = async () => {
        // Pull the integrator settings from the parent window
        if (window.parent !== window) {
            if (!app.integrator) {
                // We cannot access the parent on a crossorigin domain, so we try/catch to detect this.
                try {
                    const { type, facade } = window.parent.baiIntegrator;
                    app.integrator = new IntegratorController(type, facade);
                } catch {
                    app.integrator = false;
                }
            }
        } else {
            app.integrator = false;
        }

        app.isConstrained = !!app.integrator || app.isMobileOrTablet;

        if (app.integrator) {
            await app.integrator.tryRedirectToPresentation();
        }
    }

    constructor(integratorType, facade) {
        this.type = integratorType;
        this._queryParams = `utm_source=${this.type}_embed&utm_medium=integration_partner`;
        this._facade = facade;
    }

    async registerPresentation(presentation) {
        try {
            !presentation.links && presentation.loadPresentationLinks();
            const isEditable = presentation.permissions.owner || presentation.permissions.write;
            if (!isEditable) {
                if (!await ShowMessageDialog({
                    title: "Unable to embed presentation",
                    message: <>
                        <p>In order to embed this presentation, you must have <b>edit permissions.</b></p>
                        <br/>
                        <p>Since you do not have edit permissions on this presentation, you cannot embed it.</p>
                        <br/>
                        <p>Please contact the owner of this presentation to resolve this issue.</p>
                    </>
                })) {
                    return;
                }
            } else if (presentation.getPrivacySetting() !== PresentationPrivacyType.PUBLIC) {
                if (!await ShowConfirmationDialog({
                    title: "Make this presentation public?",
                    message: <>
                        <p>In order to embed your presentation, its privacy setting must be set to <b>Public.</b></p>
                        <br/>
                        <p>Public presentations can only be viewed by people who have been given a link or invited to the presentation.</p>
                    </>
                })) {
                    return;
                }

                await presentation.setPrivacySetting(PresentationPrivacyType.PUBLIC);
            }
            await presentation.links.loadModels();
            // If we already have an existing link for this particular integrator, use it
            const matches = presentation.links.models
                .filter(({ attributes }) => {
                    const typeMatch = attributes.type === LinkType.INTEGRATOR;
                    const nameMatch = attributes.name === `integration-${app.integrator.type}`;
                    return typeMatch && nameMatch;
                });
            let link;
            if (matches.length) {
                link = await linkDS.getLinkById(presentation, matches[0].id);
            // Otherwise, create a new one
            } else {
                link = await linkDS.createIntegratorLink(presentation);
                await linkDS.saveLink(presentation, link);
            }
            const url = link.getLinkURL();
            await this._facade.setRedirect(url);

            this.tryRedirectToPresentation();
        } catch (err) {
            console.error(err);
        }
    }

    async tryRedirectToPresentation() {
        try {
            if (this.type) {
                let targetUrl = await this._facade.getRedirect();
                if (targetUrl) {
                    targetUrl = `${targetUrl}?${this._queryParams}`;
                    const pageUrl = window.location.href;
                    if (targetUrl !== pageUrl) {
                        window.location.href = targetUrl;
                    }
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    async showPresentationList() {
        try {
            // Clear the embed url
            await this._facade.setRedirect(null);
            // Redirect to the home route
            window.location.href = `${window.location.origin}/login`;
        } catch (err) {
            console.error(err);
        }
    }
}
