import React from "react";

import { app } from "js/namespaces";

const { Provider, Consumer } = React.createContext();

/**
 * Base player context class
 */
export default class PlayerContext extends React.Component {
    constructor() {
        super();

        // Scaffolding for the context data, defines the schema and the default values
        this.contextData = {
            isLoggedIn: false,
            presentation: null,
            slides: {},
            slidesMetadata: {},
            showBranding: false,
            isCreatorOldBasicUser: false,
            isMobileOrTablet: app.isMobileOrTablet,
            requireEmail: false,
            username: app.user?.get("email"),
            allowCopyLink: false,
            allowSocialSharing: false,
            onSwitchPresentation: false,
            onEditPresentation: false,
            onDownloadPdf: false,
            onCopyDeck: false,
            creator: null,
            isPresenterAvailable: false,
            presenterUrl: null,
            presenterWindowTarget: "_blank",
            analytics: null
        };
    }

    /**
     * Method for rendering the children with the generated context, should be used
     * after the context is built
     */
    renderChildrenWithContext() {
        const { children } = this.props;

        return (
            <Provider value={this.contextData}>
                <Consumer>
                    {children}
                </Consumer>
            </Provider>
        );
    }

    /* eslint-disable react/require-render-return */
    render() {
        throw new Error("This is a base context provider class and should never be used directly");
    }
    /* eslint-enable react/require-render-return */
}
