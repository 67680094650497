import React, { Component } from "react";
import styled from "styled-components";
import { Icon, TextField, Typography, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import Api from "js/core/api";
import getLogger from "js/core/logger";
import { BeautifulDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";
import { Gap10, Gap20 } from "legacy-js/react/components/Gap";
import { BlueButton } from "legacy-js/react/components/UiComponents";
import Spinner from "legacy-js/react/components/Spinner";
import { validateLinkPassword } from "legacy-js/react/views/PresentationSettings/dataservices/LinkDataService";

const logger = getLogger();

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 40px;
    gap: 10px;
    .title {
        font-size: 20px;
        font-weight: 600;
    }
    .description {
        font-size: 16px;
        font-weight: 400;
    }
`;

interface Props {
    linkId: string;
    closeDialog: Function;
}

interface State {
    password: string;
    showPassword: boolean;
    loading: boolean;
    errorMessage: string;
}

export default class LinkPasswordDialog extends Component<Props, State> {
    state: State = {
        password: "",
        showPassword: true,
        loading: false,
        errorMessage: "",
    };

    handleViewPresentation = async () => {
        this.setState({ loading: true });

        try {
            const { linkId } = this.props;
            const { password } = this.state;

            const { idToken } = await Api.playerCheckPassword.post({ linkId, password });

            this.props.closeDialog(idToken);
        } catch (err) {
            logger.error(err, "[LinkPasswordDialog] handleViewPresentation()");

            this.setState({ loading: false, errorMessage: err.message });
        }
    };

    handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const password = event.target.value.trim();
        const errorMessage = validateLinkPassword(password);

        this.setState({ password: event.target.value, errorMessage });
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // Prevent window.keydown events from firing.
        event.stopPropagation();
    }

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key == "Enter") {
            this.handleViewPresentation();
        }
    }

    render() {
        const { closeDialog } = this.props;
        const { password, showPassword, errorMessage, loading } = this.state;

        return (
            <BeautifulDialog
                preventClose
                closeDialog={closeDialog}
                closeButton={false}
                style={{ zIndex: 10000 }}
            >
                <DialogContent>
                    <Container>
                        <Icon color="primary" fontSize="large">lock</Icon>
                        <div className="title">This link is password protected</div>
                        <Gap10 />
                        <div className="description">
                            Please enter the password to view this presentation.{<br />}Password is case sensitive.
                        </div>
                        <Gap20 />

                        <TextField
                            label="Enter Password"
                            type={showPassword ? "text" : "password"}
                            onKeyPress={this.handleKeyPress}
                            onKeyDown={this.handleKeyDown}
                            onChange={event => this.setState({ password: event.target.value, errorMessage: "" })}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.setState({ showPassword: !showPassword })}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {errorMessage && (
                            <Typography style={{ width: "100%", textAlign: "start" }} variant="caption" color="error">
                                {errorMessage}
                            </Typography>
                        )}
                        <Gap20 />
                        <BlueButton // @ts-ignore
                            onClick={this.handleViewPresentation}
                            style={{ width: "60%" }}
                            disabled={!!validateLinkPassword(password)}
                        >
                            {loading && <Spinner />}
                            {!loading && "View Presentation"}
                        </BlueButton>
                    </Container>
                </DialogContent>
            </BeautifulDialog>
        );
    }
}
